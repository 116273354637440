/******************************************************************
  Theme Name: Staging
  Description: Staging bootstrap tamplate
  Author: Colorib
  Author URI: https://www.colorib.com//
  Version: 1.0
  Created: Colorib
******************************************************************/

/*------------------------------------------------------------------
[Table of contents]

1.  Template default CSS
	1.1	Variables
	1.2	Mixins
	1.3	Flexbox
	1.4	Reset
2.  Helper Css
3.  Header Section
4.  Hero Section
5.  Banner Section
6.  Product Section
7.  Intagram Section
8.  Latest Section
9.  Contact
10.  Footer Style
-------------------------------------------------------------------*/

/*----------------------------------------*/

/* Template default CSS
/*----------------------------------------*/

html,
body {
  height: 100%;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #111111;
  font-weight: 400;
  font-family: "Aldrich", sans-serif;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}
a {
  text-decoration: none !important;
}
p {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: #707070;
  font-weight: 400;
  line-height: 26px;
  margin: 0 0 15px 0;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: #ffffff;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
  margin-bottom: 40px;
}

.section-title span {
  color: #dfa667;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 12px;
}

.section-title h2 {
  font-size: 42px;
  color: #111111;
  line-height: 50px;
  text-transform: uppercase;
}

.set-bg {
  /* background-image: url("https://source.unsplash.com/random/1600x900"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.spad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}

/* buttons */

.primary-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  padding: 16px 50px;
  color: #ffffff;
  position: relative;
}

.primary-btn.normal-btn {
  color: #111111;
}

.primary-btn.normal-btn:before {
  border-color: #c4c4c4;
}

.primary-btn.normal-btn:after {
  border-color: #c4c4c4;
}

.primary-btn:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 28px;
  width: 28px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  content: "";
  border-radius: 0 0 0 4px;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.primary-btn:after {
  position: absolute;
  right: 0;
  top: 0;
  height: 28px;
  width: 28px;
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  content: "";
  border-radius: 0 4px 0 0;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.primary-btn:hover:before {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.primary-btn:hover:after {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.site-btn {
  font-size: 16px;
  color: #ffffff;
  background: #dfa667;
  font-weight: 600;
  border: none;
  display: inline-block;
  padding: 14px 36px;
}

/* Preloder */

#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000;
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

/*---------------------
  Header
-----------------------*/

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 30px 0 0;
}

.header.header-normal {
  position: relative;
  padding: 30px 0 30px;
}

.header__logo a {
  display: inline-block;
}

.header__menu {
  text-align: center;
}

.header__menu ul li {
  list-style: none;
  display: inline-block;
  position: relative;
  margin-right: 40px;
}

.header__menu ul li.active a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.header__menu ul li:hover a:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.header__menu ul li:hover .dropdown {
  top: 32px;
  opacity: 1;
  visibility: visible;
}

.header__menu ul li:last-child {
  margin-right: 0;
}

.header__menu ul li .dropdown {
  position: absolute;
  left: 0;
  top: 62px;
  width: 140px;
  background: #ffffff;
  text-align: left;
  padding: 5px 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.header__menu ul li .dropdown li {
  display: block;
  margin-right: 0;
}

.header__menu ul li .dropdown li a {
  font-size: 14px;
  color: #111111;
  font-weight: 400;
  padding: 5px 20px;
  text-transform: capitalize;
}

.header__menu ul li .dropdown li a:after {
  display: none;
}

.header__menu ul li a {
  font-size: 15px;
  color: #ffffff;
  display: block;
  font-weight: 600;
  padding: 5px 0;
  position: relative;
}

.header__menu ul li a:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: #dfa667;
  content: "";
  -webkit-transition: all, 0.4s;
  -o-transition: all, 0.4s;
  transition: all, 0.4s;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.header__widget {
  text-align: right;
}

.header__widget span {
  font-size: 14px;
  font-family: "Aldrich", sans-serif;
  color: #b7b7b7;
  display: block;
  margin-bottom: 10px;
}

.header__widget h4 {
  font-size: 22px;
  color: #dfa667;
}

.offcanvas-menu-wrapper {
  display: none;
}

.canvas__open {
  display: none;
}

/*---------------------
  Hero
-----------------------*/

.hero {
  position: relative;
}

.hero__items {
  height: 960px;
  padding: 0 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.hero__text {
  max-width: 820px;
  text-align: center;
}

.hero__text h2 {
  color: #ffffff;
  font-size: 72px;
  line-height: 80px;
  text-transform: uppercase;
  margin-bottom: 45px;
  position: relative;
  top: 100px;
  opacity: 0;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.hero__text .primary-btn {
  position: relative;
  top: 100px;
  opacity: 0;
  -webkit-transition: all, 0.6s;
  -o-transition: all, 0.6s;
  transition: all, 0.6s;
}

.hero__text .more_btn {
  font-size: 14px;
  color: #b7b7b7;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: 55px;
}

.hero__text .hero__social {
  position: absolute;
  right: 145px;
  bottom: 55px;
}

.hero__text .hero__social a {
  display: inline-block;
  font-size: 18px;
  color: #ffffff;
  margin-right: 36px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.hero__text .hero__social a:hover {
  color: #dfa667;
}

.hero__text .hero__social a:last-child {
  margin-right: 0;
}

.hero__slider.owl-carousel .owl-item.active .hero__text h2 {
  top: 0;
  opacity: 1;
}

.hero__slider.owl-carousel .owl-item.active .hero__text .primary-btn {
  top: 0;
  opacity: 1;
}

.hero__slider.owl-carousel .owl-dots {
  display: none;
}

.hero__slider.owl-carousel .owl-nav button {
  font-size: 28px;
  color: #ffffff;
  position: absolute;
  left: 145px;
  top: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
  margin-top: -25px;
}

.hero__slider.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 145px;
}

.hero__slider.owl-carousel .owl-nav button:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 20px;
  border-left: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  content: "";
  border-radius: 0 0 0 4px;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.hero__slider.owl-carousel .owl-nav button:after {
  position: absolute;
  right: 0;
  top: 0;
  height: 20px;
  width: 20px;
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  content: "";
  border-radius: 0 4px 0 0;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.hero__slider.owl-carousel .owl-nav button:hover:before {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.hero__slider.owl-carousel .owl-nav button:hover:after {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.slide-num {
  position: absolute;
  left: 145px;
  bottom: 55px;
  z-index: 9;
}

.slide-num span {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  margin-right: 140px;
}

.slide-num span:last-child {
  margin-right: 0;
}

.slider__progress {
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  left: 180px;
  bottom: 67px;
  z-index: 9;
}

.slider__progress span {
  background: #ffffff;
  height: 2px;
  display: block;
  -webkit-transition: all, 0.4s;
  -o-transition: all, 0.4s;
  transition: all, 0.4s;
  position: relative;
  top: -1px;
}

/*---------------------
  Project Slider
-----------------------*/

.project {
  padding: 0 15px;
}

.project__slider__item {
  height: 600px;
  width: 25vw !important;
  padding: 30px !important;
  border: 20px solid #fff;
  position: relative;
  overflow: hidden;
}

.project__slider__item:hover .project__slider__item__hover {
  bottom: 30px;
}

.project__slider__item:hover .project__slider__item__hover span {
  left: 0;
}

.project__slider__item:hover .project__slider__item__hover h5 {
  right: 0;
}

.project__slider.owl-carousel .col-lg-3 {
  max-width: 100%;
}

.project__slider.owl-carousel .owl-nav button {
  height: 50px;
  width: 50px;
  background: #ffffff;
  font-size: 28px;
  color: #111111;
  position: absolute;
  left: 35px;
  top: 50%;
  margin-top: -25px;
  line-height: 54px;
  text-align: center;
}

.project__slider.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 35px;
}

.project__slider__item__hover {
  text-align: center;
  background: #ffffff;
  position: absolute;
  left: 25px;
  bottom: -500px;
  width: calc(100% - 50px);
  padding: 25px 15px;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  overflow: hidden;
}

.project__slider__item__hover span {
  color: #dfa667;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
  position: relative;
  left: 30px;
  -webkit-transition: all, 1s;
  -o-transition: all, 1s;
  transition: all, 1s;
}

.project__slider__item__hover h5 {
  color: #111111;
  font-size: 20px;
  text-transform: uppercase;
  position: relative;
  right: 30px;
  -webkit-transition: all, 1s;
  -o-transition: all, 1s;
  transition: all, 1s;
}

/*---------------------
  Testimonial
-----------------------*/

.testimonial__carousel {
  position: relative;
  overflow: hidden;
  background: #ffffff;
  padding: 75px 60px 90px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
  box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
}

.testimonial__carousel:after {
  position: absolute;
  content: "";
  left: calc(50% - 85px);
  bottom: -115px;
  width: 170px;
  height: 170px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  -webkit-box-shadow: inset 0 0 21px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 21px rgba(0, 0, 0, 0.2);
}

.testimonial__carousel button.slick-arrow {
  font-size: 28px;
  color: #111111;
  position: absolute;
  left: 30px;
  top: 50%;
  display: inline-block;
  height: 50px;
  width: 50px;
  margin-top: -25px;
  background: transparent;
  border: none;
}

.testimonial__carousel button.slick-arrow.slick-next {
  left: auto;
  right: 30px;
}

.testimonial__carousel button.slick-arrow:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 20px;
  width: 20px;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  content: "";
  border-radius: 0 0 0 4px;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.testimonial__carousel button.slick-arrow:after {
  position: absolute;
  right: 0;
  top: 0;
  height: 20px;
  width: 20px;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  content: "";
  border-radius: 0 4px 0 0;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.testimonial__carousel button.slick-arrow:hover:before {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.testimonial__carousel button.slick-arrow:hover:after {
  height: 100%;
  width: 100%;
  border-radius: 4px;
}

.testimonial__item {
  text-align: center;
}

.testimonial__item p {
  font-size: 24px;
  color: #111111;
  font-weight: 300;
  font-style: italic;
  line-height: 48px;
}

.testimonial__client {
  margin-top: -75px;
  margin-bottom: 50px;
}

.testimonial__client .slick-track {
  -webkit-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}

.testimonial__client .slick-slide {
  padding-top: 80px;
}

.testimonial__client .slick-slide {
  width: 100px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.testimonial__client .slick-slide.slick-center {
  width: 180px;
}

.testimonial__client .testimonial__client__pic img {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  width: 180px;
}

.slick-active.slick-center {
  padding-top: 35px;
}

.slick-active.slick-center .testimonial__client__pic {
  height: 150px;
  width: 150px;
  position: absolute;
  left: 0px;
  top: -30px;
  right: -15px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding-top: 5px;
}

.slick-active.slick-center .testimonial__client__pic img {
  margin: 0 auto;
  width: 150px;
  height: 150px;
}

.slick-active.slick-center .testimonial__client__text {
  display: block;
  margin-top: -8px;
}

.testimonial__client__item {
  text-align: center;
  cursor: pointer;
}

.testimonial__client__item .testimonial__client__pic {
  height: 70px;
  width: 70px;
  margin: 0 auto;
}

.testimonial__client__item .testimonial__client__pic img {
  border-radius: 50%;
}

.testimonial__client__item .testimonial__client__text {
  display: none;
}

.testimonial__client__item h5 {
  color: #111111;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  margin-bottom: 5px;
}

.testimonial__client__item span {
  font-size: 14px;
  color: #dfa667;
  display: block;
}

.logo__carousel.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.logo__carousel .logo__carousel__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/*---------------------
  Team
-----------------------*/

.team {
  padding-top: 120px;
  padding-bottom: 90px;
}

.team__btn {
  text-align: right;
}

.team__item {
  height: 480px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.team__item:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(17, 17, 17, 0.9);
  content: "";
  z-index: -1;
  opacity: 0;
  -webkit-transition: all, 0.5s, ease-out, 0.5s;
  -o-transition: all, 0.5s, ease-out, 0.5s;
  transition: all, 0.5s, ease-out, 0.5s;
}

.team__item:hover:after {
  opacity: 1;
}

.team__item:hover .team__text .team__title {
  border-bottom: 1px solid rgba(183, 183, 183, 0.2);
  padding: 0 0 32px;
  margin-bottom: 34px;
  opacity: 0;
  visibility: hidden;
  position: relative;
  bottom: initial;
  opacity: 1;
  visibility: visible;
}

.team__item:hover .team__text p {
  opacity: 1;
  visibility: visible;
}

.team__item:hover .team__text .team__social {
  opacity: 1;
  visibility: visible;
  bottom: 34px;
}

.team__text {
  height: 100%;
  position: relative;
  padding: 35px 30px 40px;
}

.team__text .team__title {
  position: absolute;
  left: 0;
  bottom: 42px;
  width: 100%;
  padding: 0 30px;
  -webkit-transition: all, 0.7s, ease-out, 0.7s;
  -o-transition: all, 0.7s, ease-out, 0.7s;
  transition: all, 0.7s, ease-out, 0.7s;
}

.team__text .team__title h5 {
  color: #ffffff;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 6px;
}

.team__text .team__title span {
  font-size: 14px;
  color: #dfa667;
}

.team__text p {
  color: #b7b7b7;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.7s, ease-out, 0.7s;
  -o-transition: all, 0.7s, ease-out, 0.7s;
  transition: all, 0.7s, ease-out, 0.7s;
}

.team__text .team__social {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.7s, ease-out, 0.7s;
  -o-transition: all, 0.7s, ease-out, 0.7s;
  transition: all, 0.7s, ease-out, 0.7s;
}

.team__text .team__social a {
  display: inline-block;
  font-size: 20px;
  color: #ffffff;
  margin-right: 34px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.team__text .team__social a:last-child {
  margin-right: 0;
}

.team__text .team__social a:hover {
  color: #dfa667;
}

/*---------------------
  Call To Action
-----------------------*/

.callto {
  padding-top: 130px;
  padding-bottom: 135px;
}

.callto__text span {
  font-size: 16px;
  color: #dfa667;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 35px;
}

.callto__text h2 {
  font-size: 42px;
  color: #ffffff;
  line-height: 52px;
  text-transform: uppercase;
  margin-bottom: 36px;
}

.callto__text .primary-btn {
  background: #dfa667;
  color: #ffffff;
  border-radius: 4px;
}

.callto__text .primary-btn:before {
  display: none;
}

.callto__text .primary-btn:after {
  display: none;
}

/*---------------------
  Latest
-----------------------*/

.latest {
  padding-top: 120px;
  padding-bottom: 90px;
}

.latest__btn {
  text-align: right;
}

.latest__item {
  -webkit-box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
  box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
  margin-bottom: 30px;
}

.latest__item img {
  min-width: 100%;
}

.latest__item:hover a {
  padding-left: 0;
}

.latest__item:hover a:after {
  left: auto;
  right: -50px;
}

.latest__item__text {
  padding-top: 25px;
  background: #ffffff;
  padding: 25px 35px 20px;
}

.latest__item__text span {
  color: #b7b7b7;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}

.latest__item__text h4 {
  color: #111111;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 12px;
}

.latest__item__text a {
  font-size: 14px;
  color: #111111;
  font-weight: 600;
  position: relative;
  padding-left: 40px;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.latest__item__text a:after {
  position: absolute;
  left: 0;
  top: 8px;
  height: 1px;
  width: 30px;
  background: #dfa667;
  content: "";
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

/*---------------------
  Project
-----------------------*/

.project__item {
  margin-bottom: 50px;
  text-align: center;
}

.project__item img {
  min-width: 100%;
  margin-bottom: 26px;
}

.project__item h4 a {
  color: #111111;
  text-transform: uppercase;
}

/*---------------------
  Project Details
-----------------------*/

.project-details {
  padding-top: 120px;
  padding-bottom: 90px;
}

.project-details img {
  margin-bottom: 30px;
  min-width: 100%;
}

.project__sidebar__about {
  margin-bottom: 35px;
}

.project__sidebar__about h2 {
  color: #111111;
  line-height: 50px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.project__sidebar__about p {
  margin-bottom: 26px;
}

.project__sidebar__about p:last-child {
  margin-bottom: 0;
}

.product__details__widget__item {
  margin-bottom: 30px;
}

.product__details__widget__item span {
  color: #707070;
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}

.product__details__widget__item h4 {
  color: #111111;
  text-transform: uppercase;
}

.product__details__widget__item p {
  color: #111111;
  font-size: 24px;
  line-height: 36px;
}

/*---------------------
  Footer
-----------------------*/

.footer {
  padding-top: 90px;
}

.footer__top {
  border-bottom: 1px solid rgba(183, 183, 183, 0.2);
  padding-bottom: 75px;
  margin-bottom: 85px;
}

.footer__top__text h2 {
  color: #ffffff;
  font-size: 38px;
  text-transform: uppercase;
}

.footer__top__newslatter form {
  position: relative;
}

.footer__top__newslatter form input {
  width: 100%;
  height: 50px;
  color: #b7b7b7;
  padding-left: 30px;
  font-size: 14px;
  border: none;
}

.footer__top__newslatter form input::-webkit-input-placeholder {
  color: #b7b7b7;
}

.footer__top__newslatter form input::-moz-placeholder {
  color: #b7b7b7;
}

.footer__top__newslatter form input:-ms-input-placeholder {
  color: #b7b7b7;
}

.footer__top__newslatter form input::-ms-input-placeholder {
  color: #b7b7b7;
}

.footer__top__newslatter form input::placeholder {
  color: #b7b7b7;
}

.footer__top__newslatter form button {
  font-size: 14px;
  color: #ffffff;
  background: #dfa667;
  border: none;
  height: 100%;
  padding: 0 20px;
  position: absolute;
  right: 0;
  top: 0;
}

.footer__about {
  margin-bottom: 30px;
}

.footer__about .footer__logo {
  margin-bottom: 24px;
}

.footer__about .footer__logo a {
  display: inline-block;
}

.footer__about p {
  margin-bottom: 30px;
}

.footer__about ul {
  margin-bottom: 38px;
}

.footer__about ul li {
  font-size: 15px;
  list-style: none;
  line-height: 26px;
  color: #707070;
}

.footer__about .footer__social a {
  font-size: 18px;
  color: #ffffff;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  margin-right: 30px;
}

.footer__about .footer__social a:hover {
  color: #dfa667;
}

.footer__about .footer__social a:last-child {
  margin-right: 0;
}

.footer__widget {
  margin-bottom: 30px;
}

.footer__widget h6 {
  color: #ffffff;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
}

.footer__widget ul li {
  list-style: none;
}

.footer__widget ul li a {
  font-size: 15px;
  color: #707070;
  line-height: 36px;
}

.footer__address {
  margin-bottom: 30px;
}

.footer__address h6 {
  color: #ffffff;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
}

.footer__address p {
  margin-bottom: 30px;
}

.footer__address ul li {
  font-size: 15px;
  list-style: none;
  line-height: 26px;
  color: #707070;
}

.copyright {
  border-top: 1px solid rgba(183, 183, 183, 0.2);
  padding: 25px 0;
  margin-top: 60px;
}

.copyright__text p {
  margin-bottom: 0;
}

.copyright__text p i {
  color: #dfa667;
}

.copyright__text p a {
  color: #ffffff;
}

.copyright__widget {
  text-align: right;
}

.copyright__widget a {
  font-size: 14px;
  color: #707070;
  margin-right: 36px;
  position: relative;
}

.copyright__widget a:after {
  position: absolute;
  right: -22px;
  top: -3px;
  content: "|";
}

.copyright__widget a:last-child {
  margin-right: 0;
}

.copyright__widget a:last-child:after {
  display: none;
}

/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-option {
  padding-top: 170px;
  padding-bottom: 120px;
}

.breadcrumb__text h2 {
  font-size: 42px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.breadcrumb__links a {
  font-size: 15px;
  color: #ffffff;
  display: inline-block;
  position: relative;
  margin-right: 12px;
}

.breadcrumb__links a:after {
  position: absolute;
  right: -13px;
  top: 1px;
  content: "~";
}

.breadcrumb__links span {
  font-size: 15px;
  color: #dfa667;
  display: inline-block;
}

/*---------------------
  About
-----------------------*/

.about {
  padding-top: 120px;
  padding-bottom: 165px;
  overflow: hidden;
}

.about-page {
  padding-top: 120px;
  padding-bottom: 90px;
}

.about__text {
  padding-top: 80px;
}

.about__text.about__page__text {
  padding-top: 0;
}

.about__text.about__page__text .section-title {
  margin-bottom: 12px;
}

.about__text .section-title {
  margin-bottom: 35px;
}

.about__para__text {
  margin-bottom: 70px;
}

.about__para__text p {
  margin-bottom: 26px;
}

.about__para__text p:last-child {
  margin-bottom: 0;
}

.about__pic {
  padding-left: 34px;
}

.about__pic__inner {
  position: relative;
  display: inline-block;
}

.about__pic__inner:after {
  position: absolute;
  right: -50px;
  top: 50px;
  height: 600px;
  width: 470px;
  border: 15px solid rgba(185, 175, 134, 0.2);
  content: "";
  z-index: 2;
}

.about__page__services__text {
  margin-bottom: 30px;
}

/*---------------------
  Services
-----------------------*/

.services {
  padding-top: 120px;
  padding-bottom: 90px;
}

.services.services-page {
  padding-bottom: 80px;
}

.services.services-page .services__item p {
  margin-bottom: 12px;
}

.services.services-page .services__item ul li {
  color: #111111;
  font-size: 15px;
  line-height: 36px;
  list-style: none;
}

.services__item {
  margin-bottom: 30px;
}

.services__item h4 {
  color: #111111;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 22px;
}

.services__item p {
  margin-bottom: 0;
}

/*---------------------
  Counter
-----------------------*/

.counter {
  padding-bottom: 70px;
}

.counter .counter__content {
  border: none;
  padding-top: 0;
  margin-top: 0;
}

.counter .counter__item .counter__item__text h5 {
  color: #ffffff;
}

.counter__content {
  border-top: 1px solid rgba(183, 183, 183, 0.2);
  padding-top: 70px;
  margin-top: 40px;
}

.counter__item {
  margin-bottom: 30px;
}

.counter__item__number {
  float: left;
  margin-right: 28px;
}

.counter__item__number h2 {
  color: #dfa667;
  font-size: 60px;
  line-height: 52px;
}

.counter__item__text {
  overflow: hidden;
}

.counter__item__text h5 {
  color: #111111;
  line-height: 24px;
  text-transform: uppercase;
}

/*---------------------
  Clients
-----------------------*/

.clients {
  padding-top: 120px;
  padding-bottom: 60px;
}

.clients img {
  margin-bottom: 60px;
}

/*---------------------
    Blog
-----------------------*/

.blog {
  padding-top: 120px;
  padding-bottom: 120px;
}

.blog__item__list .blog__item:first-child {
  border: none;
  margin-bottom: 0;
}

.blog__item__list .blog__item:last-child {
  margin-bottom: 0;
}

.blog__item {
  margin-bottom: 55px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 55px;
}

.blog__item img {
  -webkit-box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
  box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
}

.blog__item ul {
  margin-bottom: 18px;
}

.blog__item ul li {
  list-style: none;
  font-size: 14px;
  color: #b7b7b7;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 50px;
  position: relative;
  letter-spacing: 2px;
}

.blog__item ul li:after {
  position: absolute;
  right: -30px;
  top: -16px;
  content: ".";
  font-size: 15px;
  color: #b7b7b7;
  font-size: 25px;
}

.blog__item ul li:last-child {
  margin-right: 0;
}

.blog__item ul li:last-child:after {
  display: none;
}

.blog__item h2 {
  color: #111111;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  line-height: 48px;
  margin-bottom: 30px;
}

.blog__item img {
  min-width: 100%;
}

.blog__item__text {
  padding: 35px 50px 0;
}

.blog__item__text span {
  font-size: 14px;
  color: #111111;
  font-weight: 600;
  position: relative;
  padding-left: 40px;
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.blog__item__text span:after {
  position: absolute;
  left: 0;
  top: 8px;
  height: 1px;
  width: 30px;
  background: #111111;
  content: "";
  -webkit-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.blog__item__quote {
  background: #dfa667;
  padding: 35px 50px;
  position: relative;
  margin-bottom: 55px;
}

.blog__item__quote:after {
  position: absolute;
  right: 50px;
  bottom: 26px;
  content: "“";
  font-family: "Aldrich", sans-serif;
  font-size: 120px;
  color: #ffffff;
  opacity: 0.5;
  line-height: 0px;
}

.blog__item__quote p {
  color: #ffffff;
  font-size: 20px;
  font-family: "Aldrich", sans-serif;
  line-height: 32px;
  text-transform: uppercase;
}

.blog__item__quote span {
  color: #ffffff;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 4px;
}

.blog__pagination {
  padding-top: 30px;
}

.blog__pagination a {
  font-size: 15px;
  color: #111111;
  font-weight: 600;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border: 2px solid transparent;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  margin-right: 12px;
}

.blog__pagination a i {
  font-size: 15px;
  font-weight: 600;
}

.blog__pagination a:last-child {
  margin-right: 0;
}

.blog__pagination a:hover {
  border: 2px solid #dfa667;
}

/*---------------------
  Blog Sidebar
-----------------------*/

.blog__sidebar__about {
  text-align: center;
  margin-bottom: 60px;
}

.blog__sidebar__about .author__pic {
  height: 140px;
  width: 140px;
  border-radius: 50%;
}

.blog__sidebar__about p {
  color: #353535;
  margin-top: 22px;
  margin-bottom: 25px;
}

.blog__sidebar__search {
  margin-bottom: 55px;
}

.blog__sidebar__search form {
  position: relative;
}

.blog__sidebar__search form input {
  width: 100%;
  font-size: 15px;
  color: #b7b7b7;
  border: none;
  background: transparent;
  border-bottom: 1px solid #b7b7b7;
  padding-bottom: 12px;
}

.blog__sidebar__search form input::-webkit-input-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__search form input::-moz-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__search form input:-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__search form input::-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__search form input::placeholder {
  color: #b7b7b7;
}

.blog__sidebar__search form button {
  font-size: 15px;
  color: #b7b7b7;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
}

.blog__sidebar__categories {
  margin-bottom: 40px;
}

.blog__sidebar__categories h5 {
  font-size: 20px;
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.blog__sidebar__categories ul li {
  list-style: none;
  font-size: 15px;
  color: #b7b7b7;
  line-height: 42px;
}

.blog__sidebar__feature {
  margin-bottom: 55px;
}

.blog__sidebar__feature h5 {
  font-size: 20px;
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.blog__sidebar__feature__item {
  display: block;
  overflow: hidden;
  margin-bottom: 25px;
}

.blog__sidebar__feature__item:last-child {
  margin-bottom: 0;
}

.blog__sidebar__feature__item__pic {
  float: left;
  margin-right: 20px;
}

.blog__sidebar__feature__item__text {
  overflow: hidden;
}

.blog__sidebar__feature__item__text span {
  font-size: 12px;
  color: #b7b7b7;
  font-weight: 600;
  letter-spacing: 2px;
  display: block;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.blog__sidebar__feature__item__text h6 {
  color: #111111;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  line-height: 26px;
}

.blog__sidebar__tag {
  margin-bottom: 60px;
}

.blog__sidebar__tag h5 {
  font-size: 20px;
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.blog__sidebar__tag a {
  display: inline-block;
  font-size: 13px;
  color: #b7b7b7;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 12px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  position: relative;
  margin-bottom: 4px;
}

.blog__sidebar__tag a:after {
  position: absolute;
  right: -10px;
  top: 7px;
  height: 4px;
  width: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  content: "";
}

.blog__sidebar__tag a:last-child:after {
  display: none;
}

.blog__sidebar__tag a:hover {
  color: #111111;
}

.blog__sidebar__social {
  margin-bottom: 55px;
}

.blog__sidebar__social h5 {
  font-size: 20px;
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.blog__sidebar__social a {
  display: inline-block;
  height: 34px;
  width: 34px;
  font-size: 18px;
  color: #707070;
  line-height: 34px;
  text-align: center;
  border: 1px solid transparent;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  margin-right: 46px;
  position: relative;
}

.blog__sidebar__social a:after {
  position: absolute;
  right: -40px;
  top: 15px;
  height: 1px;
  width: 30px;
  background: rgba(0, 0, 0, 0.2);
  content: "";
}

.blog__sidebar__social a:last-child {
  margin-right: 0;
}

.blog__sidebar__social a:last-child:after {
  display: none;
}

.blog__sidebar__social a:hover {
  color: #dfa667;
  border-color: #dfa667;
}

.blog__sidebar__newslatter h5 {
  font-size: 20px;
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.blog__sidebar__newslatter form {
  position: relative;
}

.blog__sidebar__newslatter form input {
  width: 100%;
  font-size: 15px;
  color: #b7b7b7;
  border: none;
  background: transparent;
  border-bottom: 1px solid #b7b7b7;
  padding-bottom: 12px;
}

.blog__sidebar__newslatter form input::-webkit-input-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__newslatter form input::-moz-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__newslatter form input:-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__newslatter form input::-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__sidebar__newslatter form input::placeholder {
  color: #b7b7b7;
}

.blog__sidebar__newslatter form button {
  font-size: 18px;
  color: #111111;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
}

/*---------------------
  Blog Details
-----------------------*/

.blog__details {
  padding-top: 120px;
  padding-bottom: 120px;
}

.blog__details__content .blog__item__quote {
  margin-bottom: 40px;
}

.blog__details__content .blog__item__quote p {
  text-transform: none;
  font-size: 24px;
  line-height: 36px;
}

.blog__details__title {
  margin-bottom: 32px;
}

.blog__details__title ul {
  margin-bottom: 18px;
}

.blog__details__title ul li {
  list-style: none;
  font-size: 14px;
  color: #b7b7b7;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  margin-right: 50px;
  position: relative;
  letter-spacing: 2px;
}

.blog__details__title ul li:after {
  position: absolute;
  right: -30px;
  top: -16px;
  content: ".";
  font-size: 15px;
  color: #b7b7b7;
  font-size: 25px;
}

.blog__details__title ul li:last-child {
  margin-right: 0;
}

.blog__details__title ul li:last-child:after {
  display: none;
}

.blog__details__title h2 {
  color: #111111;
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 30px;
}

.blog__details__title img {
  margin-bottom: 32px;
  min-width: 100%;
  -webkit-box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
  box-shadow: 0px 15px 60px rgba(67, 69, 70, 0.1);
}

.blog__details__title p {
  font-size: 18px;
  color: #7d8184;
  line-height: 32px;
  margin-bottom: 0;
}

.blog__details__text {
  margin-bottom: 35px;
}

.blog__details__text p {
  font-size: 18px;
  color: #7d8184;
  line-height: 32px;
}

.blog__details__text p:last-child {
  margin-bottom: 0;
}

.blog__details__pic {
  margin-bottom: 35px;
}

.blog__details__pic img {
  min-width: 100%;
  margin-bottom: 30px;
}

.blog__details__widget {
  margin-bottom: 45px;
}

.blog__details__tags {
  margin-bottom: 20px;
}

.blog__details__tags span {
  font-size: 13px;
  color: #111111;
  margin-right: 6px;
}

.blog__details__tags a {
  display: inline-block;
  font-size: 13px;
  color: #b7b7b7;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 12px;
  -webkit-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  position: relative;
  margin-bottom: 4px;
}

.blog__details__tags a:after {
  position: absolute;
  right: -10px;
  top: 7px;
  height: 4px;
  width: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  content: "";
}

.blog__details__tags a:last-child:after {
  display: none;
}

.blog__details__tags a:hover {
  color: #111111;
}

.blog__details__social {
  text-align: right;
  margin-bottom: 20px;
}

.blog__details__social a {
  display: inline-block;
  font-size: 18px;
  color: #353535;
  margin-right: 28px;
}

.blog__details__social a:last-child {
  margin-right: 0;
}

.blog__details__author {
  background: #f8f8f8;
  padding: 50px;
  overflow: hidden;
  margin-bottom: 40px;
}

.blog__details__author__pic {
  float: left;
  margin-right: 50px;
  height: 140px;
  width: 140px;
  border-radius: 50%;
}

.blog__details__author__text {
  overflow: hidden;
}

.blog__details__author__text h5 {
  color: #111111;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 12px;
}

.blog__details__author__text p {
  color: #7d8184;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
}

.blog__details__btns {
  margin-bottom: 50px;
}

.blog__details__btns__item {
  margin-bottom: 25px;
  display: block;
  overflow: hidden;
}

.blog__details__btns__item.blog__details__btns__item--next
  .blog__details__btns__item__pic {
  float: right;
  margin-left: 30px;
  margin-right: 0;
}

.blog__details__btns__item.blog__details__btns__item--next
  .blog__details__btns__item__text {
  text-align: right;
}

.blog__details__btns__item__pic {
  float: left;
  margin-right: 30px;
}

.blog__details__btns__item__text {
  overflow: hidden;
  padding-top: 16px;
}

.blog__details__btns__item__text h6 {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  color: #8d8d8d;
  margin-bottom: 6px;
}

.blog__details__btns__item__text h6 span {
  font-size: 20px;
  position: relative;
  top: 3px;
}

.blog__details__btns__item__text h5 {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: #353535;
  font-weight: 600;
}

.blog__details__comment__form h4 {
  color: #111111;
  text-transform: uppercase;
  margin-bottom: 35px;
}

.blog__details__comment__form form input {
  font-size: 15px;
  color: #b7b7b7;
  width: 100%;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  margin-bottom: 30px;
}

.blog__details__comment__form form input::-webkit-input-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form input::-moz-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form input:-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form input::-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form input::placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form textarea {
  font-size: 15px;
  color: #b7b7b7;
  width: 100%;
  height: 130px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 20px;
  padding-top: 12px;
  resize: none;
  margin-bottom: 24px;
}

.blog__details__comment__form form textarea::-webkit-input-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form textarea::-moz-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form textarea:-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form textarea::-ms-input-placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form textarea::placeholder {
  color: #b7b7b7;
}

.blog__details__comment__form form button {
  background: #111111;
}

/*---------------------
  Contact
-----------------------*/

.contact {
  padding-top: 120px;
  padding-bottom: 120px;
}

.contact__text {
  margin-bottom: 50px;
}

.contact__text .section-title {
  margin-bottom: 12px;
}

.contact__text p {
  margin-bottom: 0;
}

.contact__widget__item {
  position: relative;
  margin-bottom: 30px;
}

.contact__widget__item.last__item:after {
  display: none;
}

.contact__widget__item:after {
  position: absolute;
  right: 50px;
  top: 0px;
  width: 1px;
  height: 50px;
  background: #e1e1e1;
  content: "";
}

.contact__widget__item__icon {
  float: left;
  margin-right: 20px;
}

.contact__widget__item__text {
  overflow: hidden;
}

.contact__widget__item__text h5 {
  color: #dfa667;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.contact__widget__item__text span {
  color: #111111;
  display: block;
}

.map {
  height: 460px;
  margin-top: 40px;
  margin-bottom: 75px;
  -webkit-box-shadow: 0px 3px 15px rgba(0, 59, 85, 0.1);
  box-shadow: 0px 3px 15px rgba(0, 59, 85, 0.1);
}

.map iframe {
  width: 100%;
}

.contact__form__text {
  margin-bottom: 35px;
}

.contact__form__text .section-title {
  margin-bottom: 12px;
}

.contact__form__text p {
  margin-bottom: 0;
}

.contact__form form input {
  height: 50px;
  width: 100%;
  font-size: 15px;
  color: #b7b7b7;
  padding-left: 20px;
  border: 1px solid #e1e1e1;
  margin-bottom: 30px;
}

.contact__form form input::-webkit-input-placeholder {
  color: #b7b7b7;
}

.contact__form form input::-moz-placeholder {
  color: #b7b7b7;
}

.contact__form form input:-ms-input-placeholder {
  color: #b7b7b7;
}

.contact__form form input::-ms-input-placeholder {
  color: #b7b7b7;
}

.contact__form form input::placeholder {
  color: #b7b7b7;
}

.contact__form form textarea {
  height: 170px;
  width: 100%;
  font-size: 15px;
  color: #b7b7b7;
  padding-left: 20px;
  padding-top: 12px;
  border: 1px solid #e1e1e1;
  resize: none;
  margin-bottom: 24px;
}

.contact__form form textarea::-webkit-input-placeholder {
  color: #b7b7b7;
}

.contact__form form textarea::-moz-placeholder {
  color: #b7b7b7;
}

.contact__form form textarea:-ms-input-placeholder {
  color: #b7b7b7;
}

.contact__form form textarea::-ms-input-placeholder {
  color: #b7b7b7;
}

.contact__form form textarea::placeholder {
  color: #b7b7b7;
}

/*--------------------------------- Responsive Media Quaries -----------------------------*/

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 1450px) {
  .container {
    max-width: 1170px;
  }
  .header {
    padding: 30px 130px 0;
  }
}

/* Medium Device = 1200px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about__pic {
    padding-left: 0;
  }
  .about__pic__inner:after {
    left: 8px;
    top: 8px;
  }
  .header__menu ul li {
    margin-right: 25px;
  }
  .hero__slider.owl-carousel .owl-nav button {
    left: 15px;
  }
  .hero__slider.owl-carousel .owl-nav button.owl-next {
    right: 15px;
  }
}

/* Tablet Device = 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero__slider.owl-carousel .owl-nav button {
    left: 0;
  }
  .hero__slider.owl-carousel .owl-nav button.owl-next {
    right: 0;
  }
  .about__text {
    padding-top: 0;
    margin-bottom: 40px;
  }
  .about__pic {
    padding-left: 0;
  }
  .blog__sidebar {
    padding-top: 50px;
  }
  .hero__text .hero__social {
    right: 80px;
  }
  .slide-num {
    left: 70px;
  }
  .slider__progress {
    left: 106px;
  }
  .project__slider__item {
    width: 50vw !important;
  }
  .canvas__open {
    display: block;
    font-size: 22px;
    color: #ffffff;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 34px;
  }
  .offcanvas-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
    z-index: 98;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    visibility: hidden;
  }
  .offcanvas-menu-overlay.active {
    visibility: visible;
  }
  .offcanvas-menu-wrapper {
    position: fixed;
    left: -300px;
    width: 300px;
    height: 100%;
    background: #111111;
    padding: 50px 20px 30px 30px;
    display: block;
    z-index: 99;
    overflow-y: auto;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    opacity: 0;
  }
  .offcanvas-menu-wrapper.active {
    opacity: 1;
    left: 0;
  }
  .offcanvas__logo {
    margin-bottom: 30px;
  }
  .offcanvas__logo a {
    display: inline-block;
  }
  .slicknav_btn {
    display: none;
  }
  .slicknav_menu {
    background: transparent;
    padding: 0;
    margin-bottom: 30px;
  }
  .slicknav_nav ul {
    margin: 0;
  }
  .slicknav_nav .slicknav_row,
  .slicknav_nav a {
    padding: 7px 0;
    margin: 0;
    color: #ffffff;
    font-weight: 600;
  }
  .slicknav_nav .slicknav_arrow {
    color: #ffffff;
  }
  .slicknav_nav .slicknav_row:hover {
    border-radius: 0;
    background: transparent;
    color: #ffffff;
  }
  .slicknav_nav a:hover {
    border-radius: 0;
    background: transparent;
    color: #ffffff;
  }
  .slicknav_nav {
    display: block !important;
  }
  .offcanvas__widget span {
    font-size: 14px;
    font-family: "Aldrich", sans-serif;
    color: #b7b7b7;
    display: block;
    margin-bottom: 10px;
  }
  .offcanvas__widget h4 {
    font-size: 22px;
    color: #dfa667;
  }
  .header__menu {
    display: none;
  }
  .header__widget {
    display: none;
  }
}

/* Wide Mobile = 480px */

@media only screen and (max-width: 767px) {
  .hero__text h2 {
    font-size: 34px;
    line-height: 45px;
  }
  .hero__items {
    height: 710px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 40px;
  }
  .about__text {
    padding-top: 0;
    margin-bottom: 40px;
  }
  .about__pic {
    padding-left: 0;
  }
  .project__slider__item {
    width: 100vw !important;
  }
  .blog__sidebar {
    padding-top: 50px;
  }
  .canvas__open {
    display: block;
    font-size: 22px;
    color: #ffffff;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 2px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 34px;
  }
  .offcanvas-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    content: "";
    z-index: 98;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    visibility: hidden;
  }
  .offcanvas-menu-overlay.active {
    visibility: visible;
  }
  .offcanvas-menu-wrapper {
    position: fixed;
    left: -300px;
    width: 300px;
    height: 100%;
    background: #111111;
    padding: 50px 20px 30px 30px;
    display: block;
    z-index: 99;
    overflow-y: auto;
    -webkit-transition: all, 0.5s;
    -o-transition: all, 0.5s;
    transition: all, 0.5s;
    opacity: 0;
  }
  .offcanvas-menu-wrapper.active {
    opacity: 1;
    left: 0;
  }
  .offcanvas__logo {
    margin-bottom: 30px;
  }
  .offcanvas__logo a {
    display: inline-block;
  }
  .slicknav_btn {
    display: none;
  }
  .slicknav_menu {
    background: transparent;
    padding: 0;
    margin-bottom: 30px;
  }
  .slicknav_nav ul {
    margin: 0;
  }
  .slicknav_nav .slicknav_row,
  .slicknav_nav a {
    padding: 7px 0;
    margin: 0;
    color: #ffffff;
    font-weight: 600;
  }
  .slicknav_nav .slicknav_arrow {
    color: #ffffff;
  }
  .slicknav_nav .slicknav_row:hover {
    border-radius: 0;
    background: transparent;
    color: #ffffff;
  }
  .slicknav_nav a:hover {
    border-radius: 0;
    background: transparent;
    color: #ffffff;
  }
  .slicknav_nav {
    display: block !important;
  }
  .offcanvas__widget span {
    font-size: 14px;
    font-family: "Aldrich", sans-serif;
    color: #b7b7b7;
    display: block;
    margin-bottom: 10px;
  }
  .offcanvas__widget h4 {
    font-size: 22px;
    color: #dfa667;
  }
  .header__menu {
    display: none;
  }
  .header__widget {
    display: none;
  }
  .about__pic__inner {
    display: block;
  }
  .about__pic__inner img {
    min-width: 100%;
  }
  .about__pic__inner:after {
    left: 0;
    width: 100%;
  }
  .hero__slider.owl-carousel .owl-nav button {
    left: 0;
  }
  .hero__slider.owl-carousel .owl-nav button.owl-next {
    right: 0;
  }
  .hero__text h2 {
    font-size: 46px;
    line-height: 58px;
  }
  .hero__text .hero__social {
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: 100px;
  }
  .slide-num {
    bottom: 25px;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .slider__progress {
    left: 0;
    bottom: 35px;
    right: 0;
    margin: 0 auto;
  }
  .hero__text .more_btn {
    bottom: 62px;
  }
  .footer__top__text {
    margin-bottom: 35px;
  }
  .copyright__text {
    text-align: center;
    margin-bottom: 20px;
  }
  .copyright__widget {
    text-align: center;
  }
  .project__sidebar {
    padding-top: 20px;
  }
  .blog__details__tags {
    text-align: center;
  }
  .blog__details__social {
    text-align: center;
  }
  .blog__details__author__pic {
    float: none;
    margin-right: 0;
  }
  .blog__details__author__text {
    padding-top: 40px;
  }
  .project {
    padding: 0;
  }
  .about__para__text {
    margin-bottom: 35px;
  }
  .clients {
    padding-bottom: 90px;
  }
  .clients a {
    margin-bottom: 30px;
  }
  .testimonial__carousel.owl-carousel .owl-nav button {
    left: 0;
  }
  .testimonial__carousel.owl-carousel .owl-nav button.owl-next {
    right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .team__btn {
    margin-bottom: 40px;
    text-align: left;
  }
  .latest__btn {
    margin-bottom: 40px;
    text-align: left;
  }
}

/* Small Device = 320px */

@media only screen and (max-width: 479px) {
  .section-title h2 {
    font-size: 28px;
    line-height: 1;
  }
  .hero__text h2 {
    font-size: 28px;
    line-height: 42px;
  }
  .hero__items {
    height: 610px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 40px;
  }
  .testimonial__item p {
    font-size: 16px;
    line-height: normal;
  }
  .about__pic__inner:after {
    display: none;
  }
  .callto__text h2 {
    font-size: 30px;
    line-height: 46px;
  }
  .project__slider.owl-carousel .owl-nav button {
    left: 15px;
  }
  .project__slider.owl-carousel .owl-nav button.owl-next {
    right: 15px;
  }
  .project {
    padding: 0;
  }
  .breadcrumb__text h2 {
    font-size: 32px;
  }
  .testimonial__carousel {
    padding: 60px 45px 65px;
  }
  .slick-active.slick-center .testimonial__client__pic img,
  .slick-active.slick-center .testimonial__client__pic {
    width: 110px;
    height: 110px;
    opacity: 1;
  }
  .testimonial__client__item .testimonial__client__pic {
    width: 110px;
    height: 110px;
    opacity: 0;
  }
  .testimonial__carousel:after {
    left: calc(50% - 70px);
    bottom: -80px;
    width: 140px;
    height: 140px;
  }
  .testimonial__carousel button.slick-arrow {
    left: 10px;
    height: 40px;
    width: 40px;
  }
  .testimonial__carousel button.slick-arrow.slick-next {
    left: auto;
    right: 10px;
  }
  .testimonial__client .slick-slide {
    padding-top: 30px;
  }
  .about .section-title h2 {
    font-size: 28px;
    line-height: 45px;
  }
  .footer__top__text h2 {
    font-size: 32px;
  }
}
.header__logo-title {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}
.header__logo-title span {
  color: #dfa667;
}

.logo {
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
  display: block;
  margin: 0 auto;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
